import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { Navigate, Route, Routes } from "react-router-dom";
// import * as auth from "../../_redux/authRedux";/
import { forgetPasswordVerifyOTP } from "../../Api/authCrud";
import ReactCodeInput from "../verification_code_input"
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../../components/CustomAlert";
// import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { resendMblOTP } from "../../Api/authCrud";
import { Link, useParams } from "react-router-dom";
import { textAlign } from "@mui/system";
import "../verification_code_input/style.css";
import { resendVerificationEmail,resendMobileOtp } from "../../../../../src/components/ApiConst"
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@mui/material";

function FPVerificationotpSent(props) {
  const { org_instance_id, intl } = props;

  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phn_nbr, setphn_nbr] = useState("");
  const [country_id, setCountry_id] = useState("")
  const [otpLength, setOtpLength] = useState('')
  const history = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [language, setLanguage] = React.useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language]);

  useEffect(() => {
    let id = location.search.substring(location.search.lastIndexOf("=") + 1);
    let fetchPhnNum = id.split("/")[0]
    let countrycode = id.split("/")[1]
    let otp_length = id.split("/")[2]
    setOtpLength(otp_length)
    setphn_nbr(fetchPhnNum);
    setCountry_id(countrycode)
  }, [location]);
  let onOnclickHandler = () => {
    setLoading(true)
    forgetPasswordVerifyOTP(country_id, phn_nbr, OTP, org_instance_id)
      .then((resp) => {
        setLoading(false)
        setTimeout(() => {
          history({
            pathname: "/auth" + resp.data.data.context,
            // search: "?code=" + OTP,
          });
          window.location.reload();
        })
        setIsRequested(true);
        //   onFPVerificationotpSentSuccess(resp.data)
      })

      .catch((e) => {
        ErrorMessage({ message: e.response.data.message });
        setLoading(false)
        // onFPVerificationotpSentFailed(e)
      });
  };

  let resendOTP = () => {
    var data = {
      phn_nbr: phn_nbr,
      country_id: country_id,
      org_instance_id: org_instance_id
    }
    //otp
    resendMobileOtp(data).then((elm) => {
      SuccessMessage({ message: elm.data.data.message });

    }).catch((e) => {
      ErrorMessage({ message: e.response.data.message });
      // onFPVerificationotpSentFailed(e)
    });
    // resendVerificationEmail(data).then((elm) => {
    //   SuccessMessage({ message: elm.data.data.message });
    // }).catch((e) => {
    //   ErrorMessage({ message: e.response.data.message });
    //   // onFPVerificationotpSentFailed(e)
    // });
  };

  const [OTP, setOTP] = useState("");

  return (
    <>
      <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Login*/}
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center">
            <div className="pt-lg-10">
              <h3 className="font-size-h1">{t(`Verification`)}</h3>
            </div>
            <div className="pt-lg-10">
              <img
                src={
                  "https://user-images.githubusercontent.com/4661784/56352614-4631a680-61d8-11e9-880d-86ecb053413d.png"
                }
                style={{ width: "150px" }}
                alt="Logo"
              />

              <div className="text-muted fw-bold mt-5">
                {t(`Please enter the verification code we send to your Mobile Number`)}
                <div className="fw-bold ml-2" id="kt_login_signup">
                  {"*******"} {phn_nbr.substring(phn_nbr.lastIndexOf("+") + 10)}
                </div>
              </div>

              <div
                className="form-group fv-plugins-icon-container pl-8"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "20px",
                  textAlign: "center"
                }}
              >

                <ReactCodeInput type='number' fields={4} value={OTP} onChange={setOTP} />
              </div>

              {/* <div>OTP is 1234</div> */}

              <div className="text-center mb-10">
                {/* <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4 ms-50"
                  onClick={onOnclickHandler}
                >
                  {t(`Submit`)}
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button> */}

                <LoadingButton
                  style={{ padding: "12px" }}
                  id="kt_login_signin_submit"
                  type="submit"
                  loading={loading}
                  onClick={onOnclickHandler}
                  loadingPosition="center"
                  loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
                  className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button`}
                >
                  <span>{t(`Submit`)}</span>
                </LoadingButton>

              </div>

              <div className="font-size-h6">
                <span className="fw-bold text-gray-700">
                  {t(`Did’t receive an OTP`)}?
                </span>
                <span
                  className="ms-5 text-dark-50 text-hover-primary my-3 mr-2 ml-15"
                  style={{cursor:"pointer" }}
                  id="kt_login_forgot"
                  onClick={resendOTP}
                >
                  {t(`Resend OTP`)}
                  {/* <FormattedMessage id="AUTH.GENERAL.VERIFI_CODE" /> */}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/*end::Login*/}
      </div>
    </>
  );
}

export default FPVerificationotpSent;
