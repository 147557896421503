import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { Navigate, Route, Routes } from "react-router-dom";
// import * as auth from "../../_redux/authRedux";/
import { verifyMblOTP, verifyForgetPasswordEmail } from "../../Api/authCrud";
import ReactCodeInput from "../verification_code_input"
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../../components/CustomAlert";
// import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { resendMblOTP } from "../../Api/authCrud";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { resendVerificationEmail,resendMobileOtp } from "../../../../../src/components/ApiConst"


function FPVerificationCodeSent(props) {
  const { org_instance_id, intl } = props;

  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState("");
  // const[country_id,setCountry_id]  = useState("")
  const history = useNavigate();
  const location = useLocation();

  let otp_length = location.search.substring(location.search.lastIndexOf("=") + 1).split("/")[1];
 
  const { id } = useParams();
  const [language, setLanguage] = React.useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation();
  const countryId = localStorage.getItem("org_country_id");

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language]);

  useEffect(() => {
    let id = location.search.substring(location.search.lastIndexOf("=") + 1);
    let fetchPhnNum = id.split("/")[0]
    // let countrycode = id.split("/")[1]

    setemail(fetchPhnNum);
    // setCountry_id(countrycode)
  }, []);

  let onOnclickHandler = () => {
    verifyForgetPasswordEmail(email, OTP)
      .then((resp) => {
        history({
          pathname: "/auth" + resp.data.data.context,
          // search: "?code=" + OTP,
        });
        window.location.reload();
        setIsRequested(true);
        //   onFPVerificationotpSentSuccess(resp.data)
      })

      .catch((e) => {
        ErrorMessage({ message: e.response.data.message });
        // onFPVerificationotpSentFailed(e)
      });
  };

  let resendOTP = () => {
    var data = {
      email: email,
      country_id: countryId,
      org_instance_id: org_instance_id
    }
    resendVerificationEmail(data).then((elm) => {
      SuccessMessage({ message: elm.data.data.message });
    }).catch((e) => {
      ErrorMessage({ message: e.response.data.message });
      // onFPVerificationotpSentFailed(e)
    });
  };

  const [OTP, setOTP] = useState("");

  return (
    <>
      <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Login*/}
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center" style={{ marginTop: "120px" }} >
            <div className="pt-lg-10">
              <h3 className="font-size-h1">{t(`Verification`)}</h3>
            </div>
            <div className="pt-lg-10">
              <img
                src={
                  "https://user-images.githubusercontent.com/4661784/56352614-4631a680-61d8-11e9-880d-86ecb053413d.png"
                }
                style={{ width: "150px" }}
                alt="Logo"
              />


              <div className="text-gray-400 mb-10 text-muted fw-bold mt-5" style={{ fontSize: "20px" }}>
              We have sent a OTP to registered Email ID
              <Link to="/"> {email}</Link> <br></br>
             Please enter the code to verify your email.
                {location.state}

              </div>
              <div
                className="form-group fv-plugins-icon-container pl-8"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: "20px"
                }}
              >
                <ReactCodeInput fieldWidth={"40px"} fieldHeight={"40px"} type='text' fields={4} value={OTP} onChange={setOTP} />
              </div>
              <div className="text-center mb-10">
                {/* <Link to="/auth/login"> */}
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4 ms-50"
                  onClick={onOnclickHandler}
                >
                  {t(`Submit`)}
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              {/* )} */}

              <div className="font-size-h6">
                <span className="fw-bold text-gray-700">
                Did’t receive an email?
                </span>
                <span
                  className="ms-5 text-dark-50 text-hover-primary my-3 mr-2 ml-15"
                  style={{cursor:"pointer" }}
                  id="kt_login_forgot"
                  onClick={resendOTP}
                >
                 Resend
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FPVerificationCodeSent;
