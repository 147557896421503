import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogDiv, FormHr, Hr } from "../../../style";
import Buttons, { ButtonType } from "../../inputs/buttons/Buttons";
import LoadingButtons from "./LoadingButton";
import SendIcon from '@mui/icons-material/Send';

export interface ButtonProps {
  text: String;
  onPress: () => void;
  color?: String;
  variant?: ButtonType;
}

export interface dialogProps {
  open?: boolean;
  onClose?: (value?: string) => void;
  dialogContent?: any;
  title?: string;
  onClick?: (value?: string) => void;
  onSubmit?: (value?: string) => void;
  type?: string;
  sx?: any;
  positiveButton?: ButtonProps;
  negativeButton?: ButtonProps;
  neutralButton?: ButtonProps;
  loading?:boolean;
}

export const DialogBox: React.FC<dialogProps> = (props: any) => {
  return (
    <>
      <DialogDiv {...props}>
        <DialogTitle>{props.title}</DialogTitle>
        <FormHr />
        <div style={{ padding: "16px 24px" }}>{props.dialogContent}</div>

        <DialogActions sx={{ paddingRight: "24px", paddingBottom: "20px" }}>
          {props.negativeButton != null && (
            <Buttons
              sx={{ float: "right" }}
              onClick={props.negativeButton.onPress}
              variant={props.negativeButton.variant}
              content={props.negativeButton.text}
              color={props.negativeButton.color}
            />
          )}
          {props.positiveButton != null && (
            <Buttons
              sx={{ float: "right" }}
              onClick={props.positiveButton.onPress}
              variant={props.positiveButton.variant}
              content={props.positiveButton.text}
              color={props.positiveButton.color}
            />
          )}

          {/* {props.negativeButton == null &&  props.positiveButton == null ?  (
            <Buttons
              sx={{ float: "right" }}
              onClick={props.onClose}
              variant={ButtonType.outlined}
              content="Cancel"
            />
          ) : ""} */}

          {props.type === "Add" && (
            <Buttons
              type="submit"
              onClick={props.onSubmit}
              variant={ButtonType.contained}
              content="Submit"
            />
          )}
           {props.type === "Edit" && (
            <Buttons
              type="submit"
              onClick={props.onSubmit}
              variant={ButtonType.contained}
              content="Update"
            />
          )}
          {props.type === "Delete" && (
            <Buttons
              type="submit"
              onClick={props.onSubmit}
              variant={ButtonType.contained}
              content="Delete"
            />
          )}
          {props.type === "ok" && (
            <Buttons
              type="submit"
              onClick={props.onSubmit}
              variant={ButtonType.contained}
              content="Ok"
            />
          )}
          {props.type === "Adding" && (
            <LoadingButtons
              onClick={props.onSubmit}
             //  endIcon={<SendIcon />}
              loading={props.loading}
              loadingPosition="end"
              variant={ButtonType.contained}
              content="Submit"
             //  disabled={props.disabled == true ? "" : "disabled"}
           >Submit</LoadingButtons>
         )}
         {props.type === "Editing" && (
            <LoadingButtons
              onClick={props.onSubmit}
             //  endIcon={<SendIcon />}
              loading={props.loading}
              loadingPosition="end"
              variant={ButtonType.contained}
              content="Update"
             //  disabled={props.disabled == true ? "" : "disabled"}
           >Update</LoadingButtons>
         )}
        </DialogActions>
      </DialogDiv>
    </>
  );
};
