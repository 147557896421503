import React, { useState, useEffect } from "react";
import { Link, Navigate, Routes, Route } from "react-router-dom";
// import { toAbsoluteUrl } from "@ellantec/pearlcore_config/dist/_pearl/_helpers";
// import { ContentRoute } from "../../../../_pearl/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
// import "@ellantec/pearlcore_config/dist/_pearl/_assets/sass/pages/login/classic/login-1.scss";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import EmailVerify from "./EmailVerfy";
import SignupMobileOtpSent from "../pages/signup_email_verification/SignupMobileOtpSent";

import { SignupVerificationCodeSent } from "../pages/signup_email_verification/SignupVerificationCodeSent"
import bg from "../../../AuthRoutePage/bg-4.jpg";
import { getServiceInstance } from "../Api/authCrud"
import LoadingSpinner from "../../../components/LoaderSmall";
// import { VerificationLinkPhone } from "../../../User/verification/VerificationLinkPhone";
import {
  Navbar,
  NavbarRight,
  NavIcon,
  P,
  Bold,
  NavbarLeft,
  NavBranch,
  NavLoading,
} from "../../../style";
import Box from '@mui/material/Box';
import { Menu, IconButton, CircularProgress, FormControl, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';



export function AuthPage({
  onLoginSuccess,
  onLoginFailed,
  onSignupFailed,
  onSignupSuccess,
  onForgotPasswordSuccess,
  onForgotPasswordFailed,
  onResetPasswordSuccess,
  onResetPasswordFailed,
  onEmailVerifySuccess,
  onEmailVerifyFailed,
  onSignupMobileOtpSentSuccess,
  onSignupMobileOtpSentFailed,
  org_instance_id,
  node_id,
  base_url,
  // configDataResp
}) {
  const [loading, setLoading] = useState(true);
  const [webConfigData, setwebConfigData] = React.useState(null);
  const [acceptLanguage, setAcceptLanguage] = React.useState(localStorage.getItem("language") || "en");
  const [language, setLanguage] = React.useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language]);

  // useEffect(()=>{
  //   setAcceptLanguage(localStorage.getItem("language"))
  // },[localStorage.getItem("language")])

  const handleChange = (event) => {
    localStorage.setItem("language", event.target.value);
    setAcceptLanguage(event.target.value);
    window.location.reload();
  };
  useEffect(() => {
    setLoading(true);
    getServiceInstance(org_instance_id)
      .then((res) => {
        setwebConfigData(res.data.data) ;
        setLoading(false);
      })
      .catch((e) => {});
  }, []);


  const toAbsoluteUrl = base_url
  let location = useLocation();
  var configData = localStorage.getItem("persist:config");
  var webConfig = JSON.parse(configData)

  return (
    <>
      {/* {loading && <LoadingSpinner />} */}
      <div className=
        {`d-flex flex-column flex-root h-vh ${loading ? "d-none" : ""}`}
      >
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/* <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              background: "rgb(232 245 254 / 74%)"
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10" style={{ marginBottom: "auto", marginTop: "auto" }}>
                <img
                  alt="Logo"
                  style={{width:"100%",objectFit:"cover"}}
                  src={webConfig?.welcome_page_logo}
                />
              </Link>

              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 fw-bold	text-white">
                  &copy; {webConfig?.copyright}
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            <NavbarRight style={{ justifyContent: "right" }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={acceptLanguage}
                    label=""
                    onChange={handleChange}
                  // sx={{padding:"10px 14px"}}
                  >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"ta"}>தமிழ்</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </NavbarRight>

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Routes>
                <Route
                  exact
                  path="/login"
                  element={
                    <Login
                      org_instance_id={org_instance_id}
                      onLoginSuccess={onLoginSuccess}
                      onLoginFailed={onLoginFailed}
                      webConfig={webConfig}
                    />
                  }
                />

                <Route
                  path="/registration"
                  element={
                    <Registration
                      org_instance_id={org_instance_id}
                      node_id={node_id}
                      onSignupSuccess={onSignupSuccess}
                      onSignupFailed={onSignupFailed}
                      webConfig={webConfig}
                      // configDataResps={configDataResp}
                    />
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <ForgotPassword
                      org_instance_id={org_instance_id}
                      onForgotPasswordSuccess={onForgotPasswordSuccess}
                      onForgotPasswordFailed={onForgotPasswordFailed}
                      webConfig={webConfig}
                    />
                  }
                />

                <Route
                  path="/reset_password"
                  element={
                    <ResetPassword
                      onResetPasswordSuccess={onResetPasswordSuccess}
                      onResetPasswordFailed={onResetPasswordFailed}
                      webConfig={webConfig}
                    />
                  }
                />

                <Route
                  path="/signup-verify-code"
                  element={
                    <EmailVerify
                      org_instance_id={org_instance_id}
                      onEmailVerifySuccess={onEmailVerifySuccess}
                      onEmailVerifyFailed={onEmailVerifyFailed}
                      webConfig={webConfig}
                    />
                  }
                />
                <Route
                  path="/signed_up_verification_otp_sent_to_phone"
                  element={
                    <SignupMobileOtpSent
                      org_instance_id={org_instance_id}
                      onSignupMobileOtpSentSuccess={
                        onSignupMobileOtpSentSuccess
                      }
                      onSignupMobileOtpSentFailed={onSignupMobileOtpSentFailed}
                      webConfig={webConfig}
                    />
                  }
                />

                <Route
                  path="/signed_up_verification_otp_sent_to_email"
                  element={
                    <SignupMobileOtpSent
                      org_instance_id={org_instance_id}
                      onSignupMobileOtpSentSuccess={
                        onSignupMobileOtpSentSuccess
                      }
                      onSignupMobileOtpSentFailed={onSignupMobileOtpSentFailed}
                      webConfig={webConfig}
                    />
                  }
                />

                <Route path="/" element={<Navigate to="/auth/login" />} />
              </Routes>
            </div>
            {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 fw-bold order-2 order-sm-1 my-2">
                &copy; 2022 EllanEats
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <nav>
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                </nav>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ms-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ms-4"
                >
                  Contact
                </Link>
              </div>
            </div> */}
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
