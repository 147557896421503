import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { errorToastMsg, language } from "../helpers/Utils";
import { ORG_AUTH_REFRESH } from "../components/ApiConst";
import { HTTP_CODE } from "../pages/components/categoriesform/HttpCode";
// const location = useLocation();
//   let pathname = location.pathname;
// initiating base url
const API_URL = process.env.REACT_APP_API_URL;
var refreshToken = localStorage.getItem("ref_token")

export const headerDataTable = {
  "X-Authorization": ("Bearer " + localStorage.getItem("token")) as string,
  Authorization: "Basic cGVhcmxjb3JlOjVKS3p4UUQuNjddb0VTM3E=",
  "post-id": localStorage.getItem("postId") as string,
  "session-id": localStorage.getItem("session_id") as string,
  "service-instance-id": localStorage.getItem(
    "service-instance-id"
  ) as string,
  region: "uk",
  organization: "eln",
}

const data = localStorage.getItem("persist:config");
// if (data) {
//   let config = JSON.parse(data);
//   let url = JSON.parse(config).base_url;
//   localStorage.setItem("base_url", url ? url : process.env.REACT_APP_API_URL);
// }
const Axios = axios.create({
  baseURL: API_URL + "/",
});
// request interceptor
Axios.interceptors.request.use(
  (config) => {
    const serviceInstanceId = localStorage.getItem("service-instance-id");
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      config.headers = {
        Authorization: "Basic " + localStorage.getItem("x-token"),
        "accept-language": language(),
      };
    } else {
      if (serviceInstanceId != null) {
        config.headers = {
          "X-Authorization": ("Bearer " + localStorage.getItem("token")) as string,
          Authorization: "Basic cGVhcmxjb3JlOjVKS3p4UUQuNjddb0VTM3E=",
          "post-id": localStorage.getItem("postId") as string,
          "session-id": localStorage.getItem("session_id") as string,
          "service-instance-id": localStorage.getItem(
            "service-instance-id"
          ) as string,
          region: "uk",
          organization: "eln",
        };
      } else {
        config.headers = {
          "X-Authorization": ("Bearer " + localStorage.getItem("token")) as string,
          Authorization: "Basic cGVhcmxjb3JlOjVKS3p4UUQuNjddb0VTM3E=",
          "post-id": localStorage.getItem("postId") as string,
          "session-id": localStorage.getItem("session_id") as string,
        };
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
// response interceptor
Axios.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response ? error.response : null;
  statusResponse(statusCode);
  return Promise.reject(error);
});
// conditions for response statuses
export const statusResponse = (data: any) => {

  const refTokenHeader: any = {
    headers: {
      Authorization: "Basic " + localStorage.getItem("x-token"),
      "post-id": localStorage.getItem("postId") as string,
      "session-id": localStorage.getItem("session_id") as string,
      "service-instance-id": localStorage.getItem(
        "service-instance-id"
      ) as string,
      region: "uk",
      organization: "eln",
    }
  }

  // if (data === 401) {
    // error status changed 
    if (data.status === 401) {
    if (refreshToken !== null) {
      axios.create(refTokenHeader).post(ORG_AUTH_REFRESH, { refresh_token: refreshToken })
        .then((resp) => {
          if (resp.status == HTTP_CODE.OK) {

            localStorage.setItem("token", resp?.data?.data?.access_token);
            localStorage.setItem("ref_token", resp?.data?.data?.refresh_token);
        
          }
          else {
            localStorage.clear();
          }
        })
        .catch((e) => {
          errorToastMsg(e.message);
          localStorage.clear();
          window.location.href = "/";
        })
    } else {
      errorToastMsg(data.data.error);
    }
  }

  if (data.status >= 500) {
    errorToastMsg(data.data.message);
  }
  if (data.status === 400) {
    errorToastMsg(data.data.message);
  }
  if (data.status === 402) {
    errorToastMsg(data.data.message);
  }
  if (data.status === 424) {
    errorToastMsg(data.data.message);
  }
};
export default Axios;