import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { color } from "../../../Theme";
import { FormHelperText } from "@mui/material";

export interface selectProps {
  className?:string;
  id?: string;
  value?: any;
  label?: string;
  onChange?: (e: any) => void;
  option?: any;
  inputProps?: any;
  multiple?: any;
  input?: any;
  MenuProps?: any;
  renderValue?: any;
  selected?: any;
  name?: string;
  error?: any;
  helpertext?: any;
  helperText?: any;
  sx?: any;
  style?: any;
  variant?: "standard" | "outlined" | "filled";
  disabled?: any;
  defaultValue?:any;
  // onMenuScrollToBottom?:(e:any)=>void;
  // isClearable?:any;
}
export const SelectField: React.FC<selectProps> = (props) => {
  
  return (
    <>
      <Select {...props}>
        {(props.option ?? []).map((elm: any, i: number) => {
          return (
            <MenuItem key={i} value={elm.value}>
              {elm.name}
            </MenuItem>
          );
        })}
      </Select>
      {props?.helpertext &&
        <FormHelperText style={{ color: color.Error_Text }}>
          {props.helpertext}
        </FormHelperText>
      }
    </>
  );
};
