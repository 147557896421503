import { refreshAccessToken } from "./authCrud";
import { language } from "../../helpers/Utils"
import { useEffect } from 'react';
import Axios from "axios";
import { HTTP_CODE } from "../ApiConst";
import { toast } from "react-toastify";

export default function setupAxios(
  axios,
  // store,
  base_url,
  firm,
  token,
  ref_token,
  post_id,
  session_id,
  config,
  auth
) {
  const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/"

  function refreshAccessToken(data) {
    return Axios.post(ORG_AUTH_REFRESH, data);
  }
  axios.defaults.baseURL = base_url;

  // const language = localStorage.getItem("language");

  const refToken = ref_token;
  const postId = post_id;
  const sessionId = session_id;
  var refreshToken = localStorage.getItem("ref_token")

  // response interceptor
  axios.interceptors.response.use(undefined, (error) => {
  
    const statusCode = error.response ? error.response.status : null;
    statusResponse(statusCode);
    return Promise.reject(error);
  });

  const statusResponse = (code) => {





    // }

    // if (code >= 500) {
    //   toast.error("server error", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //   });
    // }
    // if (code === 400) {
    //   toast.error("bad request error", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //   });
    // }
  };
  axios.interceptors.request.use(
    async (config) => {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        config.headers = {
          Authorization: "Basic " + localStorage.getItem("x-token"),
          "accept-language": language(),
        };
      } else {
        config.headers = {
          Authorization: "Basic " + localStorage.getItem("x-token"),
          "X-Authorization": `Bearer ${authToken}`,
          "post-id": localStorage.getItem("postId"),
          "session-id": localStorage.getItem("session_id"),
          "service-instance-id": localStorage.getItem("service-instance-id"),
          region: "uk",
          organization: "eln",
          "Accept-Language": language()
        };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }

  );
}
