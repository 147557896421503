import React,{ useState, useEffect }  from "react";
// import { toAbsoluteUrl } from "@ellantec/pearlcore_config/dist/_pearl/_helpers";
// import { useSelector } from 'react-redux';
import  { useNavigate, useLocation, Link }from "react-router-dom";
import { useTranslation } from 'react-i18next';


export function SignupVerificationSuccess(props) {
  const history = useNavigate();

  const [logoName, setLogoName] = useState(null);
  const [appName,setAppName] = useState(null)
  // const { intl, firm } = props;
  const location = useLocation();
  const [language, setLanguage] = React.useState(localStorage.getItem("language"));
  const { t, i18n } = useTranslation();

  // useEffect(()=>{
  //   i18n.changeLanguage(language)
  // }, [language]);

  var configData = localStorage.getItem("persist:config");
 var webConfig =JSON.parse(configData)
  
 


  function handleClick(){
    history("/users/user-lists/default-card")
  }

  return (
    <>
      <div className="d-flex flex-column flex-root" style={{height:"100vh"}}>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
        {/*begin::Login*/}
			<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
       style={{

        backgroundImage: `url(${"/media/bg/progress-hd.png"})`,

      }}>
				<div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 justify-content-center">
					<div className="pt-lg-10 ">
            <img
                  alt="Logo"
                  className="h-50px mb-10"

                  src={webConfig?.logo}
                />
                </div>
					<div className="pt-lg-10 ">
          <img src={'https://freepngimg.com/thumb/green_tick/27889-3-green-tick-thumb.png'}
                style={{ width: "150px" }} alt="Logo" className="mb-10" />
						<h1 className=" text-dark mb-7" style={{fontSize:"30px", fontWeight: 600}}>{t(`Verification Successful!!`)}</h1>
						<div className="text-gray-400 mb-10 text-muted fw-bold" style={{fontSize:"20px"}}>
              {t(`Your email has been verified successfully.`)}</div>
            
						<div className="text-center mb-10">
              <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-diff-clr fw-bold px-9 py-4 my-3`}
            onClick={handleClick}
          >{t(`Continue`)}</button>
            
						</div>
						
					</div>
				</div>
				{/* <div className="d-flex flex-center flex-column-auto p-10">
					<div className="d-flex align-items-center fw-bold fs-6">
						<a href="https://keenthemes.com/faqs" className="text-muted text-hover-primary px-2">About</a>
						<a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
						<a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
					</div>
				</div> */}
			</div>
      </div>
             {/*end::Login*/}
      </div>
    </>
  );
}

