import { useNavigate, useParams } from "react-router-dom";
import { HTTP_CODE } from "../categoriesform/HttpCode";
import { toast } from "react-toastify";
import Axios, { headerDataTable, statusResponse } from "../../../redux/setupAxios";
import capitalizeFirst, { successMsg } from "../../../helpers/Utils";
import Loading from "../../../components/loading";
import { Datatable_Icons } from "../../../Theme";
import {
  SubContainer,
  TitleDiv,
  FormButtonDiv,
  StatusTitle,
  LeftFormButtonDiv,
} from "../../dashBoard_style";
import React, { useEffect, useRef, useState } from "react";
import {
  ButtonColor,
  Buttons,
  ButtonType,
} from "../../../components/inputs/buttons/Buttons";
import DialogContent from "@mui/material/DialogContent";
import {
  InputStyle,
  TextFields,
} from "../../../components/inputs/textField/TextFields";

import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { SelectField } from "../../../components/inputs/select/SelectField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import {
  Hr,
  H3,
  RowDiv,
  ColumnDiv1,
  ColumnDiv2,
  P,
  FormTitleRight,
  FormTitleLeft,
} from "../../../style";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Table } from "../../dashBoard_style";
import ReactDOM from "react-dom";
import { DialogBox } from "../../../components/feedBack/dialog/DialogBox";
import { DialogTitle } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from "formik";
import id from "date-fns/esm/locale/id/index.js";
import { data } from "jquery";
import { deleteSupplierRemove, postSupplierAdd, STORE_SUPPLIER_LIST } from "../../User/Components/ApiConst";

const $ = require("jquery");
$.DataTable = require("datatables.net");
interface MyFormValues {
  name: string;
}
const Suppliers = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  //Select
  const [view, setView] = React.useState<any>(-1);
  const handleChange = (event: SelectChangeEvent) => {
    setView(event.target.value as string);
  };

  const filterOptions = [
    {
      name: "Active",
      value: 1,
    },
    {
      name: "Inactive",
      value: 0,
    },
    {
      name: "View All",
      value: -1,
    },
  ];
  const [selectedFilterOption, setSelectedFilterOption] = React.useState<any>(1);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const [selectSupplierId, setSelectSupplierId] = React.useState(null as any);
  //useRef For Formik Outside Submit
  const ref = React.useRef(null as any);
  //Edit Value Store
  const [formValue, setFormValue] = useState<any>(null);
  //Dialog Box
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  //Form Initial Value
  const initialValues: MyFormValues = {
    name: "",
  };
  //Form Validation
  const validation = yup.object().shape({
    name: yup.string().required("Name is required"),
  });
  //Dialog Open
  const handleClickOpen = (data: any) => {
    setOpen(true);
    if (data == null) {
      setFormValue(null);
    } else {
      setFormValue(data);
    }
  };
  //Dialog Close
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
  };
  //Submit Function Add
  const handleSubmit = () => {
    var postValue = {
      "node": 1,
      "supplier_name": ref.current.values.name,
    }
    setLoading(true);

    postSupplierAdd(postValue)
      .then((resp) => {
        if (resp.status === HTTP_CODE.CREATED) {
          setOpen(false);
          setLoading(false);
          successMsg(resp.data.message);
        }

      });
  };
  // Datatable Column Data

  useEffect(() => {
    const tableHeaders = [
      {
        title: "Sl No.",
        searchable: false,
        sortable: false,
        data: null,
      },
      {
        title: "Name",
        responsivePriority: 1,
        data: "reference_id",
        defaultContent: "<i>-</i>",
      },
    
      {
        title: "Status",
        responsivePriority: 5,
        data: "status_data",
        searchable: false,
        defaultContent: "<i>-</i>",
      },
      {
        title: "Actions",
        orderable: false,
        searchable: false,
        sortable: false,
        responsivePriority: 2,
        data: "id",
      },
    ];

    const fetchData = (API_URL: any) => {
      // Destroy the previous instance
      var table = $("#data_table");
      if ($.fn.DataTable.isDataTable(table)) {
        $(table).DataTable().destroy();
        $(table).empty();
      }
      // Destroy the previous instance

      $("#data_table").DataTable({
        paging: true,
        ordering: true,
        select: false,
        ajax: {
          url:
            API_URL + STORE_SUPPLIER_LIST +
            "?custom_format=custom_datatables&status=" + selectedFilterOption,
          type: "get",
          headers: headerDataTable,

          data: {
            columnsDef: [
              "name",
              "type",
              "status_data",
            ],
          },
          dataSrc: "data",
          async: true,
          error: function (xhr: any, error: any, code: any) {
            statusResponse(xhr.responseJSON.status_code);
          }
        },

        serverSide: true,
        processing: true,
        retrieve: true,
        language: { search: "", searchPlaceholder: "Search..." },
        columns: tableHeaders,
        responsive: {
          details: {
            renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
          },
        },
        order: [],
        columnDefs: [
        
          {
            targets: -1,
            visible: true,
            render: function (data: any, type: any, row: any, meta: any) {
              return `<div class="btn-toolbar" role="toolbar">
                      <button onclick={window.orderDetailsRow("${row.reference_id}")} type="button" class="datatable-action-btn">
                        ${Datatable_Icons.Info_Icon}
                      </button>
                      <button onclick={window.statuschangeRow("${row.id}","${row.reference_id}")} type="button" class="datatable-action-btn">
                        ${Datatable_Icons.Clock_Icon}
                      </button>
                      <button onclick={window.menuIcon(event,"${row.id}","${row.reference_id}")} type="button" class="datatable-action-btn" aria-haspopup="true" aria-controls="fade-menu" aria-expanded="true" >
                      ${Datatable_Icons.List_Icon}
                    </div>`;
            },
          },
        ],

        //Row Color Change Based Active
        createdRow: function (row: any, data: any, dataIndex: any) {
          if (data.active === 0) {
            $(row).addClass("statuschangeRow");
            $(row).off("click");
          }
        },

        //Dynamic Serial Number
        fnRowCallback: function (nRow: any, aData: any, iDisplayIndex: number) {
          var table = $('#data_table').DataTable();
          var info = table.page.info();
          var pagedata = [info.page, 0].join("");
          var indexValue = iDisplayIndex + 1;
          var oSettings = this.fnSettings();
          var pageNum = parseInt(pagedata) + indexValue;
          if(info.page > 0){
            // $("td:first", nRow).html(pageNum);
            $("td:first", nRow).html(oSettings._iDisplayStart+iDisplayIndex +1);
          }else{
            $("td:first", nRow).html(iDisplayIndex + 1);
          }
          return nRow;
        },
      });
    };
    fetchData(API_URL);
  }, [selectedFilterOption, API_URL, filterOptions]);



  //Dialog Content with Formik
  const dialogContent = (
    <>
      <div>
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          onSubmit={() => {
            if (open) {
              handleSubmit();
            }
          }}
          validationSchema={validation}
        >
          {(props: FormikProps<MyFormValues>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            } = props;
            return (
              <Form>
                <DialogContent>
                  <TextFields
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant={InputStyle.outlined}
                  />

                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );

  return (
    <>
      <SubContainer>
        <RowDiv>
          <FormTitleLeft>
            <H3>Suppliers List</H3>
          </FormTitleLeft>
          <FormTitleRight>
            <Buttons
              sx={{ marginRight: "10px" }}
              variant={ButtonType.contained}
              onClick={() => handleClickOpen(null)}
              content="Add Supplier"
            />
            <DialogBox
              open={open}
              onClose={handleClose}
              type="Add"
              onSubmit={() => {
                if (ref.current !== null) {
                  ref.current.handleSubmit();
                }
              }}
              title="Add New Supplier"
              dialogContent={dialogContent}
            />
            <DialogBox
              open={editOpen}
              type="Edit"
              onSubmit={() => {
                if (ref.current !== null) {
                  ref.current.handleSubmit();
                }
              }}
              title="Edit Supplier"
              onClose={handleClose}
              dialogContent={dialogContent}
            />
            <DialogBox
              open={editOpen}
              type="Edit"
              onSubmit={() => {
                if (ref.current !== null) {
                  ref.current.handleSubmit();
                }
              }}
              title="Edit Supplier"
              onClose={handleClose}
              dialogContent={dialogContent}
            />
            <FormControl sx={{ width: "20%", marginLeft: "10px", marginRight: "10px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Option
              </InputLabel>
              <SelectField
                id="demo-simple-select"
                value={selectedFilterOption}
                label="Filter Option"
                onChange={(e) => {
                  setSelectedFilterOption(e.target.value as string);
                }}
                option={filterOptions}
              ></SelectField>
            </FormControl>
          </FormTitleRight>
        </RowDiv>
        <Hr />
        <Table
          id="data_table"
          className=" responsive nowrap"
          style={{ width: "100%", marginTop: "40px" }}
        ></Table>
        <DialogBox
          positiveButton={{
            text: "Delete",
            onPress: () => {
              setShowDeleteConfirmationDialog(false);
              // deleteProduct();
            },
            variant: ButtonType.contained,
            color: "error",
          }}
          negativeButton={{
            text: "Cancel",
            onPress: () => {
              setShowDeleteConfirmationDialog(false);
            },
            variant: ButtonType.outlined,
          }}
          title="Delete Confirmation"
          open={showDeleteConfirmationDialog}
          dialogContent={
            <div>Are you sure want to delete the supplier?</div>
          }
        />
      </SubContainer>
      {loading && <Loading />}
    </>
  );
};

export default Suppliers;
