import { toast } from "react-toastify";
import noimage from "../assets/Images/noImage.png";
import "react-toastify/dist/ReactToastify.css";

export default function capitalizeFirst(str: String) {
  if (!str) return "";
  return str.match("^[a-z]")
    ? str.charAt(0).toUpperCase() + str.substring(1)
    : str;
}



// error message toast
export const errorToastMsg = (message: any) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
  });
};

// success message toast
export const successMsg = (message: any) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
  });
};

export const imageOnErrorHandler = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.src = noimage;
  event.currentTarget.className = "error";
};

export const imageHandler = (image: any) => {
  let base_url = localStorage.getItem("base_url")
  if (!image) {
    return noimage;
  } else {
    if (image.indexOf("http://") == 0 || image.indexOf("https://") == 0) {
      return image;
    } else {
      return base_url + image;
    }
  }
}

export const getOrgIdLocal = () =>{
  return localStorage.getItem("org_id")
}


export const language = () => {
  let language = localStorage.getItem("language");
  return language as string || "en";
}