export const RadioButton = (props) => {
    const { changed, id, isSelected, label, value,isDisabled,style } = props;
    return (
      <div className="custom-radio">
        <input
          id={id}
          onChange={changed}
          value={value}
          type="radio"
          checked={isSelected}
          disabled={isDisabled}
          // style={{style}}
        />
        <label className="radio-btn-label" htmlFor={id}>{label}</label>
      </div>
    );
  };