import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { getOrgId } from './User/Components/ApiConst';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import LoadingSpinner from '../components/LoaderSmall';
const Home = () => {
  const [loading, setLoading] = useState(false);

  const pathname: string = window.location.pathname
  const [org_name, srtOrg_name] = useState<any>(pathname.replace('/', ''));
  const [showError, setShowError] = useState("");

  const [hostName, setHostName] = useState(window.location.host);
  const urlParams = new URLSearchParams(window.location.search);
  var keyParam = urlParams.get('key');

  useEffect(() => {
    setLoading(true);
    if(keyParam == null){
       keyParam = "unknown";
      // getFun(keyParam, hostName);
    }
      getFun(keyParam, hostName);
    // if (org_name !== "") {
    //   getFun(org_name, hostName);
    // } else {
    //   let empty = "unknown";
    //   getFun(empty, hostName);
    // }
  }, [org_name]);
  const getFun = (id: any, host_name: any) => {
    getOrgId(id, host_name).then((elm: any) => {
      localStorage.setItem("org_id", elm.data.data.instance_id);
      localStorage.setItem("org_country_id", elm.data.data.country_id);
      location.reload();
    }).catch((error: any) => {
      setShowError(error.message);
      setLoading(false)
      // ;
    })
  }
  const [message, setMessage] = useState<any>('');
  const [updated, setUpdated] = useState(message);
  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };
  const handleClick = () => {
    getFun(message, hostName);
  };
  return (
    <>
      {loading == true ? <>
        <div style={{ backgroundColor: "white" }}>
        </div>
      </> :
        <section className="home_parent">
          <div className="child">
            <div style={{ marginBottom: "20px" }}>
              Please Enter Your Organization Name
            </div>
            <div>
              <TextField type="text"
                id="message"
                name="message"
                label="Name"
                onChange={handleChange}
                value={message} variant="outlined" />
            </div>
            {showError !== "" ? (
              <>
                <span style={{ fontSize: "12px", color: "red" }}>{showError}</span>
              </>
            ) : (<></>)}
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" onClick={handleClick}>Submit</Button>
            </div>
          </div>
        </section>
      }
      {loading && <LoadingSpinner />}
    </>
  )
}
export default Home;