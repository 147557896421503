import styled from 'styled-components';
interface StyledComponentProps {
    bg: string;
    color: string;
}

export const FlexColumn = styled.div`
    flex-direction: column;
`
export const ServiceContent = styled.div`
justify-content: center !important;
display: flex !important;
flex-direction: column !important;
// margin-top: 15rem;
// margin-bottom: 32rem;

@media screen and (min-width: 320px) and (max-width: 992px){
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
`

export const SubContainer = styled(FlexColumn)`
margin: 1rem 1rem 0rem 1rem;
width: 97%;
    display: flex;
    gap: 1rem;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 10%);

    @media screen and (min-width: 320px) and (max-width: 1080px){
        height: 100%;
        margin: 0.5rem 0;
    }

    &&&{
        .dtSearchIcon{
            margin-bottom: 30px;
            border-radius: 10px;
            padding: 18px;

            width: 237px;
            height: 30px;
            background: #F0F0FA 0% 0% no-repeat padding-box;
            opacity: 1;
        }

        .dataTables_filter input{
            margin-bottom", "30px
        }
    }
`;

export const TitleText = styled.h3`
    height:20%;
`;

export const Printable = styled.div`

    width: auto;
    box-sizing: border-box;

  @media print{
    display: block;
    box-sizing: initial;
  }
`;

export const NotPrintable = styled.div`
display: contents;
    @media print{
        display: none;
    }
`;

export const SectionOne = styled.div`
    display: flex;
    justify-content: space-between;
    height: 40%;
    gap: 2rem;
    width: 100%;

    @media screen and (min-width: 320px) and (max-width: 1080px){
        flex-direction: column;
        align-items: center;
        height: max-content;
    }
`;

export const ColumnOne1 = styled.div`
    display; flex;
    gap: 3rem;

    @media screen and (min-width: 320px) and (max-width: 1080px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
    }
`;
export const ColumnTwo1 = styled(FlexColumn)`
    display: flex;
    height: 115%;
    width: 100%;

    @media screen and (min-width: 320px) and (max-width: 1080px){
        height: max-content;
        justify-content: center;
        align-items: center;
    }
`;
export const SectionTwo = styled.div`
        display; flex;
        gap:2rem;
        height: 26vh;

        @media screen and (min-width: 320px) and (max-width: 1080px){
            flex-direction: column;
            height: max-content;
            width: 100%;
        }
`;
export const ColumnOne2 = styled.div`
@media screen and (min-width: 320px) and (max-width: 1080px){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
`;
export const ColumnTwo2 = styled.div`
@media screen and (min-width: 320px) and (max-width: 1080px){
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
}
`;

export const Table = styled.table`
    &&& {
        table,
        th,
        td {
          text-align: center;
          margin: 10px;
          font-size: 14px;
          border-bottom: none;
        }

        .deleteRow{
            background: gray;
            pointer-events: none;
                .sorting_1 {
                    background-color:gray;
                }
        }

        td{
            border-right:none;
            &{
                ul{
                    list-style-type: none;
                    &{
                        .dtr-title{
                            font-weight: bold;
                        }
                        .dtr-data{
                            margin-left: 10px;
                        }
                    }
                    
                }
                img{
                    border-radius: 50%;
                    object-fit: cover;
                    height: 40px;
                    width: 40px;
                }
            }
        }

        tr{
            line-height : 45px;
        }
        
    }

    &&& {
        thead{
            background: whitesmoke;
    color: #000;
    font-size: 15px;
    font-weight: normal;
        }
    } 
        

`
export const ReportTable = styled.table`
    &&& {
        table,
        th,
        td {
          text-align: center;
          margin: 10px;
          font-size: 14px;
          border-bottom: none;
          
        }

        .deleteRow{
            background: gray;
            pointer-events: none;
                .sorting_1 {
                    background-color:gray;
                }
        }

        td{
            border-right:none;
            &{
                ul{
                    list-style-type: none;
                    &{
                        .dtr-title{
                            font-weight: bold;
                        }
                        .dtr-data{
                            margin-left: 10px;
                        }
                    }
                    
                }
                img{
                    border-radius: 50%;
                    object-fit: cover;
                    height: 40px;
                    width: 40px;
                }
            }
        }

        tr{
            line-height : 45px;
        }
        
    }

    &&& {
        thead{
            background: #FBE8D8;
    color: #000;
    font-size: 15px;
    font-weight: normal;
   
        }
    } 
        

`

export const TitleDiv = styled.div`
display: flex;
flex-flow: row wrap;
`;

export const ColumnDiv = styled.div`
flex: 1 1 auto;
`;
export const FormButtonDiv = styled.div`
flex: 1 1 auto;
text-align: right;
`;

export const LeftFormButtonDiv = styled.div`
flex: 1 1 auto;
text-align: left;
`

export const StatusTitle = styled.h4`
padding:10px;
`
export const Contenttext = styled.div`
display:grid;
grid-gap:20px;
width:500px;
padding:35px 40px 20px 40px;
`
export const Form = styled.form`
padding:20px;

`

export const ReportButton = styled.button`
width:130px;
height:40px;
border-radius:10px;
box-shadow:none;
border:#1BC5BD;
background-color:#1BC5BD;
color:white;
`
export const BranchChangeCard = styled.div`
display:flex;
position:relative;
cursor:pointer;
justify-content:center;
align-items:center;
position:relative;
padding:10px;
min-width:100px;
max-width:150px;
border:1ps solid #ccc;
min-height:100px;
border-radius: 10px;
margin:10px;
color:#000;

box-shadow: 0 0.3125rem 1rem -0.1875rem rgb(0 0 0 / 20%);
&::before {
    background: linear-gradient(30deg,#00c6ff,#0072ff);
    
    content: "";
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
    z-index: 0;
  };
  &:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  };
`
export const SevicesListCard = styled.div<StyledComponentProps>`
display:flex;
position:relative;
cursor:pointer;
justify-content:center;
align-items:center;
position:relative;
padding:10px;
min-width:100px;
border:1ps solid #ccc;
min-height:100px;
border-radius: 10px;
margin:10px;
color:${(props) => props.color};
background: ${(props) => props.bg};
font-weight:bold;
box-shadow: 0 0.3125rem 1rem -0.1875rem rgb(0 0 0 / 20%);
&:hover{
    background: linear-gradient(30deg,#00c6ff,#0072ff);
    color: #fff;
    };
&:hover::before {
    animation: spinAround 2s linear infinite;
  };

`
export const BorderAnimationWrapper = styled.div`
position: relative;`
export const BorderAnimationWrapperBorderTop = styled.div`
position: absolute;
  top: 0px;
  left: 50%;
  width: 0;
  height: 2px;
  margin: auto;
  opacity: 0;
  transition: all 0.2s linear 0.3s;
  background: #989898;
  ${BorderAnimationWrapper}:hover & {
    left: 0;
  width: 100%;
  opacity: 1;
  transition: all 0.2s linear;
  }
  ${BranchChangeCard}:hover & {
    left: 0;
    width: 100%;
    opacity: 1;
    transition: all 0.2s linear;
  };
`
export const BorderAnimationWrapperBorderRight = styled.div`
background:#989898;
position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 0;
  margin: auto;
  transition: all 0.1s linear 0.2s;
  ${BranchChangeCard}:hover & {
    transition: all 0.1s linear 0.2s;
    height: 100%;
  };
  ${BorderAnimationWrapper}:hover & {
    transition: all 0.1s linear 0.2s;
    height: 100%;
  }
`
export const BorderAnimationWrapperBorderLeft = styled.div`
background:#989898;
position: absolute;
top: 0;
left: 0;
width: 2px;
height: 0;
margin: auto;
transition: all 0.1s linear 0.2s;
${BranchChangeCard}:hover & {
    transition: all 0.1s linear 0.2s;
    height: 100%;
  };
${BorderAnimationWrapper}:hover & {
    transition: all 0.1s linear 0.2s;
    height: 100%;
  }
`
export const BorderAnimationWrapperBorderBottomLeft = styled.div`
background:#989898;
position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  margin: auto;
  transition: all 0.2s linear;
  ${BranchChangeCard}:hover & {
    transition: all 0.2s linear 0.3s;
  width: 100%;
  };
  ${BorderAnimationWrapper}:hover & {
    transition: all 0.2s linear 0.3s;
    width: 100%;
  }
`

export const BorderAnimationWrapperBorderBottomRight = styled.div`
border-radius:5px;
background:#989898;
position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  margin: auto;
  transition: all 0.2s linear;
  ${BranchChangeCard}:hover & {
    transition: all 0.2s linear 0.3s;
  width: 100%;
  };
  ${BorderAnimationWrapper}:hover & {
    transition: all 0.2s linear 0.3s;
  width: 100%;
  }
`

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const DashboardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  border-radius:10px;

  &.active {
    background-color: #000;
    color: white;
  }
`;

export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 300px);
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

export const GridItem = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const OrdersBlock = styled(GridItem)`
  grid-column: span 1;
`;

export const TotalBlock = styled(GridItem)`
  grid-column: span 1;
`;

export const SalesInsightsBlock = styled(GridItem)`
  grid-column: span 1;
`;

export const CustomersBlock = styled(GridItem)`
  grid-column: span 1;
`;

export const SalesGraphBlock = styled(GridItem)`
  grid-column: span 2;
`;