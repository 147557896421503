import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { connect } from "react-redux";
// import { injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
import { requestPassword, getCountryList } from "../Api/authCrud";
// import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { HTTP_CODE } from "../pages/Const";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@mui/material";
import { getCountryDataById } from '../../../ui-common/helpers/Language';

const initialValues = {
  username: "",
};

function ForgotPassword(props) {
  const { onForgotPasswordSuccess, onForgotPasswordFailed, intl, org_instance_id } = props;
  const history = useNavigate();
  const [showPhnnumInput, setShowPhnnumInput] = useState(false);
  const [showUsernameInput, setShowUsernameInput] = useState(true);
  const [fetchCountryId, setFetchCountryId] = React.useState("");
  const [isPhnNumValue, setisPhnNumValue] = useState(null)
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const org_country_id = localStorage.getItem("org_country_id");
  const countryData = getCountryDataById(org_country_id);
  const CountryDialCode = countryData?.dial_country_code;
  const [phoneNumber, setPhoneNumber] = useState('');


  const ForgotPasswordSchema = Yup.object().shape({
    // username: Yup.string()
    //   .min(3, "Minimum 3 characters")
    //   .max(50, "Maximum 50 characters")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
  });
  const [isRequested, setIsRequested] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regPhone = /^[+-]?\d*(?:[.,]\d*)?$/;

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      requestPassword(values.username, org_instance_id, fetchCountryId)
        .then((resp) => {
          disableLoading();
          if (resp.status === 202) {
            if (regEmail.test(values.username)) {
              history({
                pathname: resp.data.data.context,
                search: "?email=" + values.username + "/" + resp?.data?.data?.otp_length,
                state: resp?.data?.data?.otp_length,
              });
            } else {
              history({
                pathname: resp.data.data.context,  ///forgot_password_verification_otp_sent_to_phone
                search: "phn_num=" + values.username + "/" + org_country_id + "/" + resp?.data?.data?.otp_length,
                state: resp?.data?.data?.otp_length,
              });
            }
          }
          onForgotPasswordSuccess(resp.data);
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setSubmitting(false);
          disableLoading();
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID_EMAIL" },
              { name: values.email }
            )
          );
          onForgotPasswordFailed(e);
        });
    },
  });

  const handlechanged = (data) => {
    let isPhoneNumber = /([A-Za-z])/gm;

    let startdigit = /^\d/gm;

    let startCountryCodeSymbol = /^\+\d/gm;


    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      (!isPhoneNumber.test(data) && startdigit.test(data)) ||
      startCountryCodeSymbol.test(data)
    ) {
      setisPhnNumValue(data)
      setShowUsernameInput(false);
      setShowPhnnumInput(true);
    } else if (regEmail.test(data)) {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("username", data);
    } else {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("username", data);
    }
  };

  
  const handleOnChanged = (event) => {
    const phoneNumber = event.target.value;
    let regEmail = /@/;
    setPhoneNumber(phoneNumber);
    const extractedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    if (
      (!regEmail.test(phoneNumber))
    ){
      formik.setFieldValue('username', extractedPhoneNumber);
    }else{
      formik.setFieldValue('username', phoneNumber);
    }
  };

  const handleOnNumChanged = (...args) => {
    if (args[3] == "") {
      setShowUsernameInput(true)
      setShowPhnnumInput(false);

    }

    var countryCode = "+" + args[1].dialCode;
    var phnNum = args[0].match(/\d{10}$/);

    formik.setFieldValue("username", phnNum);
    getCountryCode(countryCode);

  };
  const getCountryCode = (countryCode) => {
    getCountryList().then((res) => {
      var data = res.data.results;
      var matchid = countryCode;

      data.forEach(function (element) {
        if (element.dial_code == matchid) {
          setFetchCountryId(element.id);

        }
      });
    });
  };
  return (
    <>
      {isRequested && (
        <Routes>
          <Route path="*" element={<Navigate to="/auth" />} />{" "}
        </Routes>
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-1 mb-lg-10">
            <h3 className="font-size-h1">{t(`Forgot Password`)} ?</h3>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {/* {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text fw-bold">{formik.status}</div>
              </div>
            ) : ( */}
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text text-center">
                {/* Please enter your registered email address. An email
                  notification with a password reset code will then be sent to
                  you. */}
                {t(`Please enter your registered Email address or PhoneNumber.`)}
              </div>
            </div>
            {/* )} */}

            {showUsernameInput ? (
              <div className="show-user-input">
                <div className="form-group fv-plugins-icon-container">
                  {/* <div className=" react-tel-input form-control form-control-lg form-control-solid"> */}
                  <input
                    style={{width:"-webkit-fill-available"}}
                    placeholder={t(`Email / Mobile Number`)}
                    //   onPaste={(e) => {
                    //   e.preventDefault();
                    //   return false;
                    // }}
                    // onCopy={(e) => {
                    //   e.preventDefault();
                    //   return false;
                    // }}
                    autoFocus="true"
                    autoComplete="off"
                    type="username"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "username"
                    )}`}
                    name="username"

                    // onInput={() => formik.setFieldTouched("username", true)}
                    // {...formik.getFieldProps("username")}
                    onChange={(e) => handlechanged(e.target.value)}
                  />

                  {formik.touched.username && formik.errors.username ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.username}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {showPhnnumInput ? (
              <>
                <div className="show-phone-input">
                  <div className="form-group fv-plugins-icon-container ">
                    {/* <ReactPhoneInput
                      className="form-control form-control-lg form-control-solid h-auto px-6"
                      type="username"
                      name="username"
                      country={CountryDialCode}
                      // country={'gb'}
                      value={isPhnNumValue != null ? isPhnNumValue : null}
                      // variant="outlined"
                      // autoComplete = "off"
                      // countryCodeEditable={false}
                      // value={formik.values.username}
                      // defaultCountry="in"
                      onChange={handleOnChanged}
                      disableDropdown={true}  // Add this line hide dropdown
                      inputProps={{
                        // name: 'phone',
                        // country:'us',
                        // required: true,
                        autoFocus: true
                      }}
                    /> */}
                    <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid h-auto px-6"
                    style={{ width: '-webkit-fill-available' }}
                    autoComplete="tel"
                    value={phoneNumber}
                    onChange={handleOnChanged}
                    // ref={phoneInputRef}
                    autoFocus
                  />
                    {formik.touched.username && formik.errors.username ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.username}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <Link
                  to="/auth/forgot-password"
                  onClick={() => {
                    setShowUsernameInput(true);
                    setShowPhnnumInput(false);
                  }}
                >
                  {" "}
                  {t(`Email / Mobile Number`)}
                </Link>
              </>
            ) : null}
            <div className="form-group d-flex flex-wrap flex-center">
              {/* <button
                id="kt_login_forgot_submit"
                type="submit"
                // onClick={formik.handleSubmit}
                className="btn btn-diff-clr font-weight-bold px-9 py-4 my-3 mx-4"
              // disabled={formik.isSubmitting}
              >
                {t(`Submit`)}
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button> */}
              <LoadingButton
                style={{ padding: "12px" }}
                id="kt_login_signin_submit"
                type="submit"
                loading={loading}
                // onClick={() => RegSubmit}
                loadingPosition="center"
                loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
                className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button`}
              >
                <span>{t(`Submit`)}</span>
                {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
              </LoadingButton>

              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {t(`Cancel`)}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
