import React, { FunctionComponent } from "react";
// import {Button} from './styles'
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
export enum ButtonType {
  text = "text",
  contained = "contained",
  outlined = "outlined",
  icon = "icon",
}
export enum ButtonColor {
  secondary = "secondary",
  success = "success",
  error = "error",
  primary = "primary",
}
export interface LoadingButtonsProps {
  variant?: any;
  content?: any;
  disabled?: any;
  href?: any;
  color?: string;
  component?: string;
  onClick?: (e: any) => void;
  endIcon?: any;
  type?: any;
  id?: string;
  sx?: any;
  style?:any;
  loadingPosition?:any;
  children:any;
  loading?:boolean;
  size?:String
}
export const LoadingButtons: FunctionComponent<LoadingButtonsProps> = (props: any) => {
  return (
    <>
      {props?.variant == "icon" ? (
        <IconButton aria-label="delete" {...props}>
          {props?.icon}
        </IconButton>
      ) : (
        <LoadingButton {...props} sx={props?.variant === ButtonType.outlined ? { boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' } : {}}>{props.content}</LoadingButton>
      )}
    </>
  );
};
export default LoadingButtons;