import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
// import * as auth from "../../_redux/authRedux";
import { verifyMblOTP } from "../../Api/authCrud";
// import ReactCodeInput from 'react-verification-code-input';
import ReactCodeInput from "../verification_code_input";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../../components/CustomAlert";
// import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { resendMblOTP } from "../../Api/authCrud";
import { verificationInprogress } from "../../../../../src/modules/Auth/Api/ApiConst";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { resendVerificationEmail,resendMobileOtp } from "../../../../../src/components/ApiConst"


function SignupMobileOtpSent(props) {

  const navigate = useNavigate();


  const { org_instance_id, onSignupMobileOtpSentSuccess, onSignupMobileOtpSentFailed, intl } =
    props;


  const { t, i18n } = useTranslation();

  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phn_nbr, setphn_nbr] = useState("");
  const [country_id, setCountry_id] = useState("")
  const location = useLocation();
 
  useEffect(() => {
    let id = location.search.substring(location.search.lastIndexOf("=") + 1);
    let fetchPhnNum = id.split("/")[0]
    let countrycode = id.split("/")[1]

    setphn_nbr(fetchPhnNum);
    setCountry_id(countrycode)


  }, []);

  let onOnclickHandler = () => {

    if (location.state.context == "/signed_up_verification_otp_sent_to_email") {
      verificationInprogress(OTP)
        .then((resp) => {
          if (resp.status === 202) {
            navigate(resp.data.data.context);
          } else {
            navigate(resp.data.data.context);

          }
        })
        .catch(() => {
          navigate("/verification-link-expired");
        });
    } else {
      var data = {
        code: OTP,
        country_id: location.state.country_id,
        phn_nbr: location.state.phn_nbr,
        org_instance_id: location.state.org_instance_id
      }
      verifyMblOTP(data)
        .then((resp) => {
          setIsRequested(true);
          // onSignupMobileOtpSentSuccess(resp.data);
          if (resp.status === 202) {
            navigate(resp.data.data.context);
          } else {
            navigate(resp.data.data.context);

          }
        })

        .catch((e) => {
          ErrorMessage({ message: e.response.data.message });
          // onSignupMobileOtpSentFailed(e);
        });

    }


  };

  let resendOTP = () => {
    // resendMblOTP(phn_nbr).then((resp) => {
    //   SuccessMessage({ message: "Resend Code Successfully Sent" });
    // });
    var data = {};
    if (location.state.context == "/signed_up_verification_otp_sent_to_email") {
      data = {
        email: location.state.email,
        org_instance_id: location.state.org_instance_id
      }
      resendVerificationEmail(data).then((elm) => {
        SuccessMessage({ message: elm.data.data.message });
      })
    } else {
      data = {
        country_id: location.state.country_id,
        phn_nbr: location.state.phn_nbr,
        org_instance_id: location.state.org_instance_id
      }
      resendMobileOtp(data).then((elm) => {
        SuccessMessage({ message: elm.data.data.message });

      })
    }
  };

  const [OTP, setOTP] = useState("");

  return (
    <>
      {isRequested && (
        // <Redirect to="/auth" />
        <Routes>
          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      )}

      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{t(`Verification`)}</h3>
            <img
              src={
                "https://user-images.githubusercontent.com/4661784/56352614-4631a680-61d8-11e9-880d-86ecb053413d.png"
              }
              style={{ width: "150px" }}
              alt="Logo"
            />

            <div className="text-muted fw-bold mt-5">
              {location.state.message}
              {/* <div className="fw-bold ml-2" id="kt_login_signup">
                {"*******"} {phn_nbr.substring(phn_nbr.lastIndexOf("+") + 10)}
              </div> */}
            </div>
          </div>

          <div
            className="form-group fv-plugins-icon-container pl-8"
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ReactCodeInput sx={{ width: "300px" }} type='text' fields={location.state.otp_length} value={OTP} onChange={setOTP} />
          </div>
          <div className="form-group d-flex flex-wrap justify-content-center align-items-center">

            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4 ms-50"
              onClick={onOnclickHandler}
            >
              {t(`Submit`)}
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <span
            className="text-dark-50 text-hover-primary my-3 mr-2 ml-15"
            id="kt_login_forgot"
            onClick={resendOTP}
            style={{ display: "flex", justifyContent: "center",cursor:"pointer" }}
          >
            {t(`Resend OTP`)} ?
          </span>
        </div>
      )}
    </>
  );
}

export default SignupMobileOtpSent;
