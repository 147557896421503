import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { register, getCountryList, ORG_AUTH_SIGNUP } from "../Api/authCrud";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ErrorMessage, SuccessMessage } from "../../../components/CustomAlert";
import SelectField from "../../../components/inputs/InputFields/SelectField";
import CheckBox from "../../../components/CheckBox";
import { HTTP_CODE } from "../../../components/ApiConst";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RadioButton } from "../../../components/inputs/InputFields/RadioButton";
import { useTranslation } from 'react-i18next';
import { getEntityTagsetList } from "../../../../src/components/ApiConst"
// import { connect } from "react-redux";
// import { FormattedMessage, injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { Login } from './Login'
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@mui/material";
// import MuiPhoneNumber from "material-ui-phone-number";
// import Popper from "@mui/material/Popper";
// import Fade from "@mui/material/Fade";
// import Paper from "@mui/material/Paper";
// import useClasses from "../../../helpers/useClasses";
import { getCountryDataById } from '../../../ui-common/helpers/Language';

const useStyles = (theme) => ({
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
});

const initialValues = {
  // title: "",
  fname: "",
  lname: "",
  // username: "",
  email: "",
  country_id: "",
  phn_nbr: "",
  password: "",
  changepassword: "",
  // status: "",
  acceptTerms: false,
  // profession: ""
};

function Registration(props) {
  // const {onLoginSuccess,onLoginFailed,intl} = props;
  const history = useNavigate();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = React.useState("");
  const [listCountryCode, setListCountryCode] = React.useState();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const { org_instance_id, node_id, onSignupSuccess, onSignupFailed, webConfig, intl, configDataResps } =
    props;

  const [loading, setLoading] = useState(false);
  const [userOption, setuserOption] = React.useState("Myself");
  // const [profession, setProfession] = React.useState("Student");
  const [showError, setShowError] = useState(false);
  const [validateErrorMsg, setValidateErrorMsg] = useState("");
  const org_country_id = localStorage.getItem("org_country_id");
  const countryData = getCountryDataById(org_country_id);
  const CountryDialCode = countryData?.dial_country_code;
  const [phoneNumber, setPhoneNumber] = useState('');
  const country_id = localStorage.getItem("org_country_id");

  // const webConfigData = useSelector((state) => state.webConfig);
 
  // const signupcheck = webConfigData?.features;
  // const has_signup_feature = signupcheck.hasOwnProperty("signup");

  // if (!has_signup_feature)
  //   history.push({
  //     pathname: "/auth/login",
  //   });
  // const theme = useSelector((state) => state.userRoleJson);
  


  //regex
  var passwordRegex = localStorage.getItem("regex");
  var passwordErrorMessage = localStorage.getItem("regex_mes");


  //Usestate
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  // const classes = useClasses(useStyles); // useStyles from custom hook
  const [fetchCountryId, setfetchCountryId] = React.useState("94");
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  // const [ProfessionList, setProfessionList] = React.useState();
  const { t, i18n } = useTranslation();

  const [loading1, setLoading1] = React.useState(true);
  const handleClick1 = () => {

    setLoading(true);
  }


  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const RegistrationSchema = Yup.object().shape({
    // profession: Yup
    //   .string()
    //   .required(t("Profession is required")),
    fname: Yup
      .string()
      .matches(/^([^0-9]*)$/, "Only alphabets are allowed")
      .matches(
        /[^\s*].*[^\s*]/g,
        "* " + t("This field cannot contain only blankspaces")
      )
      .max(30, "Must be 30 characters or less")
      .required(t("First Name is required")),

    lname: Yup
      .string()
      .max(15, t("Too Long")),


    email: Yup
      .string()
      .email(t("Wrong email format"))
      .min(3, t("Minimum") + 3 + t("symbols"))
      .max(50, t("Maximum") + 50 + t("symbols")),
    // .required(t("Email is required")),

    phn_nbr: Yup
      .number().notRequired().nullable(),
    // .matches(phoneRegExp, "Phone number is not valid")
    // .required(t("Phone Number is required")),

  //   phn_nbr: Yup
  //   .number()
  // .min(10, 'Phone number must be at least 10 digits')
  // .max(13, 'Phone number cannot exceed 13 digits'),

    password: Yup.string()
      .matches(passwordRegex, passwordErrorMessage)
      // .max(passwordRegex, t("Maximum") + 50 + t("characters"))
      .required(
        t("Password is required")
      ),


    changepassword: Yup.string()
      .required(
        t("Confirm password is required")
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("Password and confirm password didn't match")
        ),
      }),

    acceptTerms: Yup.bool().oneOf(
      [true],
      t("Accept Terms & Conditions is required")
    ),
  });

  // useEffect(() => {
  //   getEntityTagsetList(org_instance_id).then((elm) => {
  //     setProfessionList(elm?.data?.results)
  //   })
  // }, [props])

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      if (values.email !== "" || (values.phn_nbr !== "" && values.phn_nbr !== null)) {
        setShowError(false)
        axios
          .post(
            ORG_AUTH_SIGNUP,
            {
              // node_id: "1",
              org_instance_id: org_instance_id,
              country_id: country_id,
              // phn_nbr: values.phn_nbr[0],
              phn_nbr: values.phn_nbr,
              fname: values.fname,
              lname: values.lname,
              email: values.email,
              password: values.password
              // category: values.profession
            }
          )

          .then((resp) => {
            if (resp.status == HTTP_CODE.ACCEPTED) {
              setLoading(false)
              setTimeout(() => {
                if (
                  webConfig?.features?.signup?.mobileVerification
                    ?.mandatory == true && webConfig?.features?.signup?.emailVerification
                      ?.mandatory == true
                ) {
                  navigate("/auth" + resp.data.data.context, { state: resp.data.data });
                }

                else if (
                  webConfig?.features?.signup?.mobileVerification
                    ?.mandatory == true
                ) {
                  navigate("/auth" + resp.data.data.context, { state: resp.data.data });

                } else if (
                  webConfig?.features?.signup?.emailVerification
                    ?.mandatory == true
                ) {
                  if (resp.data.data.context == "/signed_up_verification_link_sent_to_email") {
                    navigate(resp.data.data.context, { state: resp.data.data });
                  } else {
                    navigate("/auth" + resp.data.data.context, { state: resp.data.data });
                  }

                } else {
                  SuccessMessage({ message: "Signup Successfull" });
                  navigate("/auth/login");
                }
              });
            }
            onSignupSuccess(resp.data);
          })
          .catch((resp) => {
            showValidationError(true, resp.response.data.message);
            // toast.error(e?.response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 3000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            // });
            if (resp.response.status == HTTP_CODE.CONFLICT) {
              // history("/auth/registration");
              navigate("/auth/registration");

              ErrorMessage({ message: resp.response.data.message });
            }
            setSubmitting(false);

            disableLoading();
            onSignupFailed(resp);
          });
      } else {
        setLoading(false);
        let msg = "Please Enter Email or Phone Number"
        showValidationError(msg);
      }

    },
  });

  const showValidationError = (msg) => {
    setShowError(true);
    setValidateErrorMsg(msg);
  }

  function RegSubmit() {
    let valArr = Object.keys(formik.values);
    valArr.every((k, i) => {
      formik.setFieldTouched(k);
      if (formik.touched[k] && !formik.errors[k]) {
        if (i === valArr.length - 1) {
          formik.handleSubmit();
        }
        i++;
        return true;
      } else {
        return false;
      }
    });
  }
  useEffect(() => {

    getCountryList()
      .then((res) => {
        setCountryCode(
          res.data.data.map((elm) => {
            return {
              id: elm.dial_code,
              name: elm.dial_code,
              flag: elm.flag,
            };
          })
        );
        setListCountryCode(res.data.data.map((elm) => elm.dial_code));
      })
      .catch((e) => {
      });
  }, []);

  const handleOnNumChanged = (...args) => {
    var countryCode = "+" + args[1].dialCode;
    var phnNum = args[0].match(/\d{10}$/);

    formik.setFieldValue("phn_nbr", phnNum);
    formik.setFieldValue("country_id", countryCode);
    getCountryCode(countryCode);
  };

  const handleOnChanged = (event) => {
    const phoneNumber = event.target.value;
    setPhoneNumber(phoneNumber);

    // Extract country code and phone number based on your logic
    // For example:
    // const countryCode = '+91'; // Replace with your logic
    const extractedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters

    formik.setFieldValue('phn_nbr', extractedPhoneNumber);
    // getCountryCode(countryCode);
  };

  const getCountryCode = (countryCode) => {
    getCountryList().then((res) => {
      var data = res.data.results;
      var matchid = countryCode;

      data.forEach(function (element) {
        if (element.dial_code == matchid) {
          setfetchCountryId(element.id);
        }
      });
    });
  };

  // const radioProfessionChangeHandler = (e) => {
  //   setProfession(e.target.value);
  // };
  // const radioChangeHandler = (e) => {
  //   setuserOption(e.target.value);
  //   setProfession(e.target.value);
  // };
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-left mb-10 mb-lg-20">
        <h1 className="">{t(`Sign Up`)}</h1>
        <p className="text-muted fw-bold">
          {t(`Enter your details to create your account`)}
        </p>
      </div>

      <div style={{ textAlign: "center" }}>

        <img width={"100%"} style={{ marginBottom: "30px" }} src={props?.webConfig?.login_page_logo} />

      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text fw-bold">{formik.status}</div>
          </div>
        )}

        {/* <div className="form-group fv-plugins-icon-container">
          <div className="radio-btn-container" style={{ display: "flex" }}>
            <section className="radio-sec">
              <RadioButton
                changed={radioChangeHandler}
                id="1"
                isSelected={userOption === "Myself"}
                label="Myself"
                value="Myself"
              />

              <RadioButton
                changed={radioChangeHandler}
                //   id="2"
                isSelected={userOption === "Others"}
                label="Others"
                value="Others"
              />
            </section>
          </div>
          {formik.touched.title && formik.errors.title ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.title}</div>
            </div>
          ) : null}
        </div> */}

        {/* <div className="form-group fv-plugins-icon-container">
              <SelectField
                className="form-control form-control-lg form-control-solid"
                name="title"
                list={titles}
                onChange={() => formik.setFieldTouched("title", true)}
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </div> */}

        {/* <div className="form-group fv-plugins-icon-container">
          <div className="select-signup-type-button">
            <SelectField
              list={ProfessionList}
              name="profession"
              {...formik.getFieldProps("profession")}
              className="form-control form-control-solid h-auto py-5 px-6 "
            />
            {formik.touched.profession && formik.errors.profession ? (
              <small className="text-danger">
                {formik.errors.profession}
              </small>
            ) : null}
          </div>
        </div> */}

        <div className="form-row d-flex  " style={{ justifyContent: "space-between", width: "100%" }}>
          <div className="form-group fv-plugins-icon-container " style={{ width: "55%" }}>
            <input
              placeholder={t(`First Name`)}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6  ${getInputClasses(
                "fname"
              )}`}
              name="fname"
              onInput={() => formik.setFieldTouched("fname", true)}
              {...formik.getFieldProps("fname")}
              style={{ width: "100%" }}
            />
            {formik.touched.fname && formik.errors.fname ? (
              <div className="fv-plugins-message-container">
                <small className="text-danger">
                  {formik.errors.fname} </small>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container " style={{ width: "25%" }}>
            <input
              placeholder={t(`Last Name`)}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "lname"
              )}`}
              name="lname"
              style={{ width: "100%" }}

              onInput={() => formik.setFieldTouched("lname", true)}
              {...formik.getFieldProps("lname")}
            />
            {formik.touched.lname && formik.errors.lname ? (
              <div className="fv-plugins-message-container">
                <small className="text-danger">
                  {formik.errors.lname} </small>
              </div>
            ) : null}
          </div>
        </div>


        {/* <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Last name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "lname"
                )}`}
                name="lname"
                onInput={() => formik.setFieldTouched("lname", true)}
                {...formik.getFieldProps("lname")}
              />
              {formik.touched.lname && formik.errors.lname ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lname}</div>
                </div>
              ) : null}
            </div> */}

        {/* <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Username"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                onInput={() => formik.setFieldTouched("username", true)}
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div> */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t(`Email`)}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            onInput={() => formik.setFieldTouched("email", true)}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <small className="text-danger">{formik.errors.email} </small>

            </div>
          ) : null}
        </div>

        <div
          className="form-group fv-plugins-icon-container"
        >
          {/* <ReactPhoneInput
            className="form-control form-control-lg form-control-solid h-auto px-6"
            country={CountryDialCode}
            // country={"gb"}
            onInput={() => formik.setFieldTouched("phn_nbr", true)}
            // defaultCountry="in"
            disableDropdown={true}  // Add this line hide dropdown
            onChange={handleOnChanged}
          /> */}
          <input
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "email"
          )}`}
            type="tel"
            name="phn_nbr"
            placeholder={t(`Mobile`)}
            // className="form-control form-control-lg form-control-solid h-auto px-6"
            autoComplete="tel"
            value={phoneNumber}
            onChange={handleOnChanged}
          />

          {formik.touched.phn_nbr && formik.errors.phn_nbr ? (
            <div className="fv-plugins-message-container">
              {/* <small className="text-danger">{formik.errors.phn_nbr} </small> */}
            </div>
          ) : null}
        </div>

        <div
          className="form-group fv-plugins-icon-container"

          onChange={(e) => setPassword(e.target.value)}
          onFocus={handleClick("top-end")}
          onBlur={handleClick("top-end")}
        >
          <input
            placeholder={t(`Password`)}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <small className="text-danger">{formik.errors.password} </small>
            </div>
          ) : null}

          {/* <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                    </Paper>
                  </Fade>
                )}
              </Popper> */}
        </div>

        <div
          className="form-group fv-plugins-icon-container"

        >
          <input
            placeholder={t(`Confirm Password`)}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            onInput={() => formik.setFieldTouched("changepassword", true)}
            {...formik.getFieldProps("changepassword")}
          />

          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <small className="text-danger">{formik.errors.changepassword} </small>
            </div>
          ) : null}
        </div>

        <div
          className={`${userOption === "Myself" ? "form-group" : "display-none"
            }`}
        >
          <CheckBox
            className="checkbox"
            name="acceptTerms"
            value="acceptTerms"
            label={
              <Link
                to="/terms"
                target="_blank"
                className="ms-1rem fs-14"
                rel="noopener noreferrer"
                style={{ color: "#2874f0" }}
              >
                {t(`I agree the Terms & Conditions`)}
              </Link>
            }
            {...formik.getFieldProps("acceptTerms")}
            isSelected={formik.values.acceptTerms ? 1 : 0}
          />
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <small className="text-danger">{formik.errors.acceptTerms} </small>
            </div>
          ) : null}
        </div>

        {/* <div
          className={`${
            userOption === "Myself" ? "display-none" : "form-group fw-bold"
          }`}
        >
          Select Profession
        </div> */}

        {/* <div
          className={`${
            userOption === "Myself"
              ? "display-none"
              : "form-group fv-plugins-icon-container"
          }`}
        >
          <div className="radio-btn-container" style={{ display: "flex" }}>
            <section className="radio-sec">
              <RadioButton
                changed={radioProfessionChangeHandler}
                id="1"
                isSelected={profession === "Student"}
                label="Student"
                value="Student"
              />

              <RadioButton
                changed={radioProfessionChangeHandler}
                //   id="2"
                isSelected={profession === "Job Seeker"}
                label="Job Seeker"
                value="Job Seeker"
              />
            </section>
          </div>
          {formik.touched.title && formik.errors.title ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.title}</div>
            </div>
          ) : null}
        </div> */}

        {
          showError ? (
            <div className="pc-form-group  text-center">
              <p style={{ color: "red" }}>{validateErrorMsg}</p>
            </div>
          ) : (
            <></>
          )
        }

        <div className="form-group d-flex flex-wrap flex-center">
          {/* <button
            type="submit"
            // disabled={formik.isSubmitting}
            onClick={() => RegSubmit}
            className="btn btn-diff-clr fw-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button> */}

          {/* <LoadingButton
            style={{ width: "100%", padding: "12px" }}
            id="kt_login_signin_submit"
            type="submit"
            loading={loading}
            onClick={() => RegSubmit}
            loadingPosition="center"
            loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
            className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button`}
          >
            <span>{t(`Sign Up`)}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </LoadingButton> */}
          <div style={{ placeItems: "center", width: "100%" }}>
  <LoadingButton
    style={{ width: "85%", padding: "12px",float: "inline-end" }}
    id="kt_login_signin_submit"
    type="submit"
    loading={loading}
    onClick={() => RegSubmit}
    loadingPosition="center"
    loadingIndicator={<CircularProgress style={{ color: 'white', size: '10px' }} />}
    className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button`}
  >
    <span>{t(`Sign Up`)}</span>
    {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
  </LoadingButton>
</div>

        </div>







        <div style={{ display: "grid", marginLeft:"23px", placeItems: "center", width: "100%" }}>
          <Link to="/auth/login"
            style={{ color: "#2874f0" }}
          >
            {/* <button
              type="button"
              className="btn btn-light-gray fw-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button> */}
            {t(`Existing User`)}? {t(`Sign In`)}
          </Link></div>
        {/* <div>Existing User? Log in</div> */}
      </form>
    </div>
  );

}

export default Registration;
