import React, { useEffect, useState } from "react";
import "./App.css";
import "datatables.net-responsive";
import "./index.css";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { themeReducer } from "./redux/ThemeReducer";
import { Suspense } from "react";
import { useNavigate } from "react-router";
import "./AuthRoutePage/pcstyle.css"
// import {
//   BrowserRouter,
//   // useRoutes,
// } from "react-router-dom";
// const AuthUI = React.lazy(
//   () => import("@ellantec/pearlcore_auth_ui/dist/AuthRoutePage")
// );
// import AuthRoutePage from '@ellantec/pearlcore_auth_ui/dist/AuthRoutePage';
import Home from "./pages/home";
import { errorToastMsg, getOrgIdLocal } from "./helpers/Utils";
import { ToastContainer } from "react-toastify";
import * as _new from "./components/setupAxios";
import axios from "axios";


import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthPage, FPVerificationExpired, FPVerificationLinkSent, FPVerificationSuccess, SignupVerificationCodeSent, SignupVerificationExpired, SignupVerificationLinkSent, SignupVerificationLoading, SignupVerificationSuccess } from "./modules/Auth";
import { PUpdateFailed } from "./modules/Auth/pages/FP_email_verification/PUpdateFailed";
import { PUpdateSuccess } from "./modules/Auth/pages/FP_email_verification/PUpdateSuccess";
import FPVerificationotpSent from "./modules/Auth/pages/FP_email_verification/FPVerificationotpSent";
import FPVerificationCodeSent from "./modules/Auth/pages/FP_email_verification/FPVerificationCodeSent";
import { gerServiceInstanceList } from "./modules/Auth/Api/ApiConst";
import { getServiceInstance } from "./modules/Auth/Api/authCrud";
import { getAuthByToken } from "./components/ApiConst";
import Axios from "./redux/setupAxios";
import { UserRoleReducer as userRole } from "./redux/UserRoleReducer";
import { themeReducer as theme } from "./redux/ThemeReducer";

const App = React.lazy(() => import("./App"));
function Root() {
  const API_URL = process.env.REACT_APP_API_URL;
  const base_url = process.env.REACT_APP_API_URL;
  const [title, setTitle] = useState('');
  const [favicon, setFavicon] = useState('');

  const ORGANIZATION_ID = getOrgIdLocal();
  const rootReducer = combineReducers({ theme, userRole })
  const store = createStore(rootReducer);
  document.title = title;
  const [userRoleJson, setUserRoleJson] = React.useState();

  // useEffect(() => {
  //   let link : any = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = favicon;
  // }, [favicon]);

  // Service Instance List API
  // useEffect(() => {
    // setLoading(true);
    // Axios.get(API_URL + "/Org/Services/List/?page_size=100")
    //   .then((value) => {
    //     const typeData = value.data.results.map((elm: any) => {
    //       return { value: elm.unique_id, name: elm.services_name };
    //     });

    //     const getService: any = typeData?.filter((elm: any) => {
    //       return elm?.name == "Store"
    //     });

    //     Axios.get(API_URL + "/Org/Services/ServiceInstances/List/?service_id=" + getService[0]?.value)
    //       .then((value) => {
    //         if (value.status == 200) {
    //           const typeData = value.data.results.map((elm: any) => {
    //             return {
    //               value: elm.service_data.service_id,
    //               name: elm.name,
    //               serviceInsId: elm.instance_id,
    //             };
    //           });
    //           localStorage.setItem("service-instance-id", typeData[0]?.serviceInsId);
    //         }
    //       })
    //   })
    //   .catch((error) => {
    //     ;
    //   })

  // }, [])

  useEffect(()=>{
    if(ORGANIZATION_ID !== null){
    webconfig();
    }
  },[ORGANIZATION_ID !== null])

  

  useEffect(() => {
    
    getAuthByToken().then((elm: any) => {
      setUserRoleJson(elm)
      localStorage.setItem("user_id", elm?.data?.data?.posts[0]?.user_data.id as any);
      // localStorage.setItem("user_id", elm?.data?.data?.person_entity?.user as any);
    });
  }, []);

  function webconfig() {
    getServiceInstance(ORGANIZATION_ID)
      .then((res: any) => {       
        // setFavicon(res.data.data.node_config_data.logo_1)
        setTitle(res.data.data.node_address_data.name)
        var configData = res.data.data;
        // setLoading(false);
        localStorage.setItem("org_instance_id", ORGANIZATION_ID as any);
        localStorage.setItem("regex", res.data.data.regex as any);
        localStorage.setItem("regex_mes", res.data.data.regex_message as any);
        localStorage.setItem(
          "service_id",
          res.data.data.service_data.service_id
        );
        localStorage.setItem(
          "node_id",
          res.data.data.primary_node
        );
        localStorage.setItem(
          "persist:config",
          JSON.stringify(configData, null, 2)
        );
        // sethas_webconfig(true);
      })
      .catch((e) => {
        // setLoading(false);
        // ErrorMessage({ message: e.response.data.message });
      });
  }

  _new.setupAxios(axios, base_url);
  const [isAuthorized, setIsAuthorized] = React.useState(
    "token" in localStorage &&
    "postId" in localStorage &&
    "session_id" in localStorage
  );
  return !isAuthorized ? (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <ToastContainer />
          {ORGANIZATION_ID !== null ? (
            <Routes>
              <Route
                path="/auth/*"
                element={
                  <AuthPage
                    org_instance_id={ORGANIZATION_ID}
                    // loginResponce={loginResponce}
                    onLoginSuccess={function (response: any): void {

                      if (response.status === 202) {
                        // setIsAuthorized(true);
                        // let RollName = response.data.data.posts[0].roles_data.landing_page_data.page_name;
                        let categoryName = response?.data?.data?.category_name;
                        let entityId = response?.data?.data?.person_entity?.user;
                        localStorage.setItem(
                          "User_name",
                          response?.data?.data?.user?.fname
                        );
                        // localStorage.setItem(
                        //   "User_name",
                        //   response.data.data.person_entity.personal_info.fname
                        // );
                        localStorage.setItem(
                          "role_name",
                          response.data.data.posts[0].roles_data.roles_name
                        );
                        if (response.data.data.category_name !== null) {
                          localStorage.setItem(
                            "category_name",
                            response.data.data.category_name
                          );
                        } else {
                          localStorage.setItem(
                            "category_name",
                            "Admin"
                          );
                        }
                        window.location.href = "/";

                        if (localStorage.getItem("service-instance-id") == null) {
                          gerServiceInstanceList().then((elm: any) => {
                            
                            localStorage.setItem("service-instance-id", elm.data.results[0].instance_id);
  
                            if (categoryName == "Admin") {
                              window.location.href = "/";
                            }
                          })
                        }

                        
                        //   setIsAuthorized(true);
                        //   window.location.href = "/";
                      }
                    }}
                    onLoginFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onForgotPasswordSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onForgotPasswordFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onResetPasswordSuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onResetPasswordFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onEmailVerifySuccess={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onEmailVerifyFailed={function (response: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupMobileOtpSentSuccess={function (
                      response: any
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    onSignupMobileOtpSentFailed={function (
                      response: any
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    node_id={1}
                    base_url={base_url}
                  />
                }
              />

              <Route
                path="/password_reset_successfully"
                element={<PUpdateSuccess />}
              />
              <Route
                path="/verification-session-expired"
                element={<PUpdateFailed />}
              />

              <Route
                path="/forgot_password_verification_otp_sent_to_email"
                element={
                  <FPVerificationCodeSent org_instance_id={ORGANIZATION_ID} />
                }
              />
              <Route
                path="/forgot_password_verification_otp_sent_to_phone"
                element={
                  <FPVerificationotpSent org_instance_id={ORGANIZATION_ID} />
                }
              />

              <Route
                path="/forgot_password_verification_link_sent_to_email"
                element={
                  <FPVerificationLinkSent org_instance_id={ORGANIZATION_ID} />
                }
              />
              <Route
                path="/forgot_password_email_verified_successfully"
                element={
                  <FPVerificationSuccess org_instance_id={ORGANIZATION_ID} />
                }
              />
              <Route
                path="/forgot_password_email_verification_failed"
                element={
                  <FPVerificationExpired org_instance_id={ORGANIZATION_ID} />
                }
              />
              <Route
                path="/password-updated-successfully"
                element={<PUpdateSuccess org_instance_id={ORGANIZATION_ID} />}
              />
              <Route
                path="/password-update-failed"
                element={<PUpdateFailed />}
              />

              {/* ----------------------------------SIGNUP------------------------------------------------------------ */}
              <Route
                path="/signed_up_verification_link_sent_to_email"
                element={
                  <SignupVerificationLinkSent
                    org_instance_id={ORGANIZATION_ID}
                  />
                }
              />
              <Route
                path="/signed_up_verification_otp_sent_to_email"
                element={
                  <SignupVerificationCodeSent
                    org_instance_id={ORGANIZATION_ID}
                  />
                }
              />
              <Route
                path="/signed_up_email_verified_successfully"
                element={
                  <SignupVerificationSuccess
                    org_instance_id={ORGANIZATION_ID}
                  />
                }
              />
              <Route
                path="/verification-link-expired"
                element={
                  <SignupVerificationExpired
                    org_instance_id={ORGANIZATION_ID}
                  />
                }
              />

              <Route
                path="/verification-in-progress/:id"
                element={
                  <SignupVerificationLoading
                    org_instance_id={ORGANIZATION_ID}
                  />
                }
              />
              {/* ----------------------------------SIGNUP------------------------------------------------------------ */}

              <Route path="*" element={<Navigate to="/auth/login" />} />
              {/* <Route path="/logout" element={<Logout />} /> */}
            </Routes>
          ) : (
            <Home />
          )}

        </BrowserRouter>
      </Suspense>
    </>
  ) : (
    <>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <App data={userRoleJson} />
        </Suspense>
        
      </Provider>
    </>
  );
}
export default Root;